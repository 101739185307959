import React, { useEffect } from 'react';
import { Container } from '@rugby-au/container';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { useAppConfig } from '@rugby-au/app-config';
import { WebHeader } from '@rugby-au/web-header';

const Login = () => {
  const { header } = useAppConfig();
  useEffect(() => {
    const loginUrl = `${process.env.NEXT_PUBLIC_AUTH_URL}login?clientId=memberships&codeChallenge=&redirectPath=/`;
    window.location.href = loginUrl;
  }, []);

  return (
    <ScrollView>
      <WebHeader {...header} />
      <Container>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
          <ActivityIndicator size={'large'} />
        </View>
      </Container>
    </ScrollView>
  );
};

export default Login;
